import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import Derps from './Pages/Derps'
import Derp from './Pages/Derp'
import Derpnet from './Pages/Derpnet'
import Feeding from './Pages/Feeding'
import Items from './Pages/Items'
import Berpmap from './Pages/Berpmap'

import { App, Lander } from './App';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      	<Route index element={<Lander />} />

   		  <Route path="derps" element={<Derps />} />
        <Route path="derp" element={<Derp />} />
        <Route path="items" element={<Items />} />
        <Route path="derpnet" element={<Derpnet />} />
        <Route path="feeding" element={<Feeding />} />
      </Route>
    </Routes>
  </BrowserRouter>
);


