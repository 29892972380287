export default function Derpnet() {
	return (
		<div className="section">
			<h1>Derpnet</h1>
			<p>Derpnet is the future home of all great things Derp. For now, it is where Derp holders can set up their Rooms.</p>
			<h2>Rooms</h2>
			<p>Rooms are a simple ERC1155Holder Contract. It maps every Derp to a Room, which consists of three things: </p>
			<ol>
				<li>bgId - The id of the room background</li>
				<li>p1Id - The id of the first poster</li>
				<li>p2Id - The id of the second poster</li>
			</ol>
			<p>Rooms cannot be transferred from one derp to another. Rooms are also owned by the Derp, not the address that set the room up.</p>
			<h3>Room Background</h3>
			<p>Room backgrounds can be free or paid. The paid rooms cost $S.</p>
			<p>There are currently two free rooms and one paid room.</p>
			<h3>Poster Frames</h3>
			<p>Every Derp room has two frames for the Derp owner to place Posters in. Posters are a subset from the ERC1155 Items Contract.</p>
			<p>When you set your posters, they are transferred to the Rooms contract. When you set a new poster to a specific frame, the old one is transferred back. You can also simply remove your posters.</p>
			<h2>Profiles</h2>
			<p>Profiles are currently unreleased on mainnet.</p>
			
		</div>
	)
}