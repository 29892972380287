export default function Items () {
	return (
		<div className="section">
			<h1>Event Items</h1>
			<p>Event Items are ERC1155 NFTs that can be minted using $DERP. They have typically launched alongside a Derpefier or Button event.</p>
			<p>The Items contract is very mutable. Every item has:</p>
			<ol>
				<li>Basic ERC1155 Stuff: id, name, description, imgdata</li>
				<li>derpCost - the cost in $DERP to mint the Item</li>
				<li>perDerpLimit - total amount mintable by each Derp token ID</li>
				<li>maxsupply - the current max supply of the item</li>
			</ol>
			<p>At the time of writing there are four types of items:</p>
			<ol>
				<li>101-9999 - Trading Cards</li>
				<li>10001 - 19999 - Posters</li>
				<li>20001 - 29999 - Trophies</li>
				<li>30001 - 39999 - Miscellaneous</li>
			</ol>
			<h2>Mint Mechanics</h2>
			<p>Standard items are released with limited supply and variable derp cost. Typical combinations have been:</p>
			<ol>
				<li>Common Trading Cards: 69 $DERP, 1 mint per Derp, 2000 Max Supply</li>
				<li>Rare Trading Cards: 420 $DERP, 1 mint per Derp, 420 Max Supply</li>
				<li>Legendary Trading Cards: 1000 $DERP, 1 mint per Derp, 100 Max Supply.</li>
				<li>Common Posters: 100 $DERP, 1000 Max Supply.</li>
				<li>Posters: 1000 $DERP, 100 Max Supply</li>
			</ol>
			<p>Supplies are capped after (or shortly after) the event is over.</p>
			<p>Items can be deployed with no cost and no per derp limit and then minted to the treasury directly. This is how we create the items for rewarding users on the leaderboard (Derpstar, Da Button, etc.). Paid Items cannot be minted by the treasury for free.</p>
			<p>Posters can be displayed in Rooms. Cards have before and may again increase your Derp claim rate.</p>
			<h2>Existing Items</h2>
			<h3>Cards</h3>
			<p>101 - Derpmander</p>
			<p>102 - Derptle</p>
			<p>103 - Peekuhdewd</p>
			<p>104 - Da Misssooor</p>
			<p>105 - Da Button</p>
			<p>106 - Moar</p>
			<p>107 - Derp Vaydar</p>
			<p>108 - Yo Duh</p>
			<p>109 - Derp Star</p>
			<p>110 - D-3RPO</p>
			<p>111 - Boba Fud</p>
			<p>112 - Derpe Wan</p>
			<h3>Posters</h3>
			<p>10001 - Derp Wars Poster</p>
			<p>10002 - Derp Cola Poster</p>
			<p>10003 - Derp Cola Classic Poster</p>
			<p>10004 - Hotter Than Hell Poster</p>
			<p>10005 - Derpface Poster</p>
			<p>10006 - Shadow Poster</p>
			<p>10007 - Metronix Poster</p>
			<p>10008 - Derp Wars II Poster</p>
			<p>10009 - Derp Wars II Poster - Green Edition</p>
			<p>10010 - Derp Wars II Poster - Tattooine Edition</p>
			<p>10011 - CNY 2025 - Lantern Poster</p>
			<p>10012 - CNY 2025 - Snake Poster</p>
			<p>10013 - Superbowl LIX - Chiefs Poster</p>
			<p>10014 - Superbowl LIX - Eagles Poster</p>
			<p>10015 - Superbowl LIX Poster</p>
			<h3>Trophies</h3>
			<p>20001 - Participation Trophy</p>
			<h3>Misc.</h3>
			<p>30001 - Condom</p>
			<h2>Links</h2>
			<p>Mint on <a href="https://shop.derpe.xyz" target="_blank">the shop</a> or purchase here: <a href="https://paintswap.io/sonic/collections/derp-event-items/listings" target="_blank">Paintswap</a></p>
		</div>

		)
}