import snackCola from '../imgs/snackCola.jpg'
import snackChips from '../imgs/snackChips.jpg'
import berp0 from '../imgs/berp0.jpg'
import berp1 from '../imgs/berp1.jpg'
import berp2 from '../imgs/berp2.jpg'
export default function Feeding() {
  return(
    <div className="section">
      <h1>Feeding</h1>
      <p>Feeding is the process of feeding a snack to a derp to mint a berp. Users will explore Derpnet for hungry Derps and feed them tasty Snackz!</p> 
      <h2>Berps</h2>
      <div className="imgs"><img src={berp0} /> <img src={berp1} /></div>
      <p>Berps can only be minted through the feeding contract. None are reserved for special minting.</p>
      <p>Berps are a large supply, low barrier of entry, ERC721 Collection.</p>
      <p>Berps tokenURI function is modifiable, and will eventually point to a separate smart contract.</p>
      <p>Berps will launch with an Initial Max Supply of 10,000. This can be increased over time. The total max supply is 69,420 Berps.</p>
      <p>Much of future development will focus on building tools to enable developers to build games around the Berps.</p>
      <h2>Snacks</h2>
      <div className="imgs"><img src={snackCola} /> <img src={snackChips} /></div>
      <p>Snacks are an ERC1155 contract. Snacks are mintable in $DERP and can be used to feed Derp NFTs. Each Snack costs a specific amount of $DERP. When fed to a Derp NFT, the Snack decreases the Derp's Hunger.</p>
      <p>The $DERP remains in the Snackz contract until the Snack is fed to a $DERP. Upon feeding:</p>
      <ol>
        <li>The Snack is burned</li>
        <li>45% of the $DERP is transferred to the Derp NFT owner.</li>
        <li>45% of the $DERP is burned.</li>
        <li>10% of the $DERP is sent to the General Treasury</li>
      </ol>
      <p>Once a Derp's Hunger reaches zero, it burps. Whoever fed it the Snack that made it burp gets a Berp NFT.</p>
      <p>The Derp that berped cannot be berped again until 24 hours have passed.</p>
      <p>Purchase snacks <a href="https://snacks.derpe.xyz/">here.</a></p>
    </div>
    )
}