import logo from './logo.svg';
import './App.css';
import {
  Link,
  Outlet
} from "react-router-dom";

const Nav = ()=> (
  <div className="App__Nav">
      <h1>Derpe Docs</h1>

      <Link to="derps" className="c2">Derps</Link>
      <Link to="derp" className="c3">$DERP</Link>
      <Link to="items" className="c4">Items</Link>
      <Link to="derpnet" className="c4">Derpnet</Link>
      <Link to="feeding" className="c4">Feeding</Link>
  
  </div>
)

export function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Nav />

      </header>
      <div className="App-body">
        <Outlet />
      </div>
    </div>
  )
}

export function Lander() {
  return(
    <div className="lander">
      <p></p>
    </div>
    )
}





