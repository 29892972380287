import derp0 from '../imgs/derp0.jpg'
import derp1 from '../imgs/derp1.jpg'
import derp2 from '../imgs/derp2.jpg'
import derp3 from '../imgs/derp3.jpg'

export default function Derps() {
 
  const treasury = 190000

  return(
    <div className="section">
      <h1>Derps</h1>
      <p>Derps are a generative NFT collection on Sonic Mainnet.</p>
      <p><a href="https://paintswap.io/sonic/collections/derps/listings">Purchase Derps on Paintswap.</a></p>
      <p>Total Supply: 2,000 Derps</p>
      <p>Mint Price: 100 $S</p>
      <p>Token IDs 1-100 were reserved for the corresponding Derpe Dewdz holder.</p>
      <h2>Derps Treasury</h2>
      <p>190,000 $S was raised in the mint, allocated to five treasuries.</p>
      <ul>
        <li><strong>Collection Market Making</strong> - 19,000 $S</li>
        <li><strong>$DERP Market Making</strong> - 38,000 $S</li>
        <li><strong>Locks and Bribes</strong> - 19,000 $S</li>
        <li><strong>General Treasury</strong> - 76,000 $S</li>
        <li><strong>Team</strong> - 38,000 $S</li>
      </ul>
      <h3>Collection Market Making</h3>
      <p>10% of the mint funds, 19,000 $S, was to be used to perform Market Making for the Derps collection. The plan was to defend the floor at mint price. Derp floor has never come close to the mint price.</p>
      <p>These funds still remain in the mint wallet, but are still allocated for this purpose.</p>
      <h3>$DERP Market Making</h3>
      <p>20% of the mint, 38,000 $S, was allocated for Market Making the $DERP ERC20 token. All of the $S was used to seed the initial liquidity of the token.</p>
      <h4>Initial Liquidity</h4>
      <p>30% of the $DERP supply will be paired with 38,000 $S on Shadow Dex. This fund will own the liquidity.</p>
      <h4>Liquidity Management</h4>
      <p>Liquidity will not be burned. We want to be able to adapt to an evolving ecosystem. If liquidity is moved from one dex to another, even partially, it will be transparent. Any farming opportunities undertaken by this fund will be partially compounded, with the remainder of the rewards allocated to the team.</p>
      <p>This fund accumulates xSHADOW and votes with 100% allocation to the DERP/S pair. All voting rewards are compounded.</p>
      <p>This fund has not broken or sold any xSHADOW at the time of writing. Nor does it hold any x33.</p>
      <p>Small amounts of liquidity on Silverswap and Wagmi have been added using trading fees claimed before the gauge was live.</p>
      <h3>Locks and Bribes</h3>
      <p>10% of the mint, 19,000 $S, will be used to purchase locks and bribe voters for $DERP liquidity.</p>
      <p>We used 6969 $S to purchase SHADOW and locked it soon after the protocol was live.</p>
      <p>12,031 $S remain in the mint wallet allocated for locks and bribes.</p>
      <h3>General Treasury</h3>
      <p>40% of the mint, 76,000 $S, is allocated to the General Treasury.</p>
      <p>This fund can be used for anything beneficial to the Derpeverse. Including the replenishment of other portions of the treasury.</p>
      <p>This treasury accumulates royalties and fees from ecosystem activities.</p>
      <p>So far none of this $S has been deployed. 6,000 $S in royalties have been used to purchase a handful of meme/project tokens.</p>

      <h3>Team</h3>
      <p>20% of the mint, 38,000 $S, is allocated to the Team.</p>
      <h2>Derpe Dewdz</h2>
      <p>Derpe Dewdz are a collection of 100 handcrafted NFTs. They were auctioned one-by-one on Paintswap on the Fantom network.</p>
      <p>They were bridged to Sonic using the SCC Bridge.</p>
      <p>You can check them out on Paintswap <a href="https://paintswap.io/sonic/collections/derpe-dewdz/listings" target="_blank">here</a>.</p>
      <h2>Derpe Beras</h2>
      <p>Derpe Beras are a generative collection of 1000 Derpe Beras. They were minted for 1 BERA each on Bera Network on launch day.</p>
      <p>You can check them out on Magic Eden <a href="https://magiceden.io/collections/berachain/0xccea61b327f074d777ebb9affdaf5581bca10918" target="_blank">here</a>.</p>
    </div>
    )
}