
export default function Derp() {
  return(
    <div className="section">
      <h1>The $DERP Token</h1>
      <p>The $DERP token is a fixed supply ERC20 token. It is claimable daily by Derp holders. It is just for fun.</p>
      <p>Total Supply // 420,696,696</p>
      <ul>
        <li>Initial Liquidity // 30%</li>
        <li>$DERP emissions // 30%</li>
        <li>General Treasury // 20%</li>
        <li>Bribes // 10%</li>
        <li>Team Locks // 10%</li>
      </ul>
      <h2>Initial Liquidity</h2>
      <p>126,209,009 $DERP was sent to the Market Making fund and added to liquidity on Shadow with 38,000 $S.</p>
      <h2>$DERP emissions</h2>
      <p>126,209,009 $DERP were allocated for emissions, both daily and event-based.</p>
      <p>Every Derp NFT can claim $DERP per day. This amount does NOT accumulate. The emission rate and frequency of claims will determine how long $DERP can be emitted for. Eventually emissions will cease.</p>
      <p>The Derp Emitter</p>
      <h2>General Treasury</h2>
      <p>84,139,393 $DERP Can be used as needed as the ecosystem evolves.</p>
      <h2>Bribes</h2>
      <p>42,069,696 $DERP is used to bribe voters on various dexes. Right now this is 420,000 $DERP/week on Shadow.</p>
      <h2>Team Locks</h2>
      <p>42,069,696 $DERP is locked until Decembder 25th, 2025 for the team.</p>
     
    </div>
    )
}